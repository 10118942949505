export const environment = {
  production: false,
  baseURL: 'https://foodcloud.in/foodies/',
  mediaURL: 'https://foodcloud.in/foodies/uploads/',
  onesignal: {
    appId: 'YOUR_APP_ID',
    googleProjectNumber: 'GOOGLE_PROJECT_NUMBER',
    restKey: 'REST_KEY'
  },
  authToken: '123456',
  app_name: 'Foodcloud',
  websiteURL: 'https://foodcloud.in',
  websiteName: 'Foodcloud',
  default_country_code: '91'
};

