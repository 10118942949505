import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cart: any[] = [];
  public itemId: any[] = [];
  public totalPrice: any = 0;
  public packagingPrice: any = 0;
  public grandTotal: any = 0;
  public coupon: any;
  public serviceTax: any = 0;
  public discount: any = 0;
  public orderTax: any = 0;
  public orderPrice: any;
  public shipping: any;
  public shippingPrice: any = 0;
  public minOrderPrice: any = 0;
  public freeShipping: any = 0;
  public datetime: any;
  public deliveryAddress: any;
  public deliveryPrice: any = 0;
  public stores: any[] = [];
  public totalItem: any;
  public bulkOrder: any[] = [];
  public flatTax: any;
  public cartStoreInfo: any;

  public havePayment: boolean;
  public haveCOD: boolean;
  public havePayUmoney: boolean;
  
  public payumoneyCreds = {
    id: '',
    key: '',
    code: ''
  };

  public payumoneyENV: any;

  constructor(
    public util: UtilService,
    private api: ApiService
  ) {
    this.util.getCouponObservable().subscribe(data => {
      if (data) {
        this.coupon = data;
        this.coupon.discount = parseFloat(data.discount);
        this.coupon.min = parseFloat(data.min);
        this.calcuate();
      }
    });
    this.util.getKeys('userCart').then((data: any) => {
      if (data && data !== null && data !== 'null') {
        const userCart = JSON.parse(data);
        if (userCart && userCart.length > 0) {
          this.cart = userCart;
          this.itemId = [...new Set(this.cart.map(item => item.id))];
          this.calcuate();
          this.getVenueDetails(userCart[0].restId);
        } else {
          this.calcuate();
        }
      } else {
        this.calcuate();
      }
    });
  }

  getVenueDetails(restId) {
    const body = {
      id: restId
    };

    this.api.post('stores/getByUid', body).then((datas: any) => {
      console.log(datas);
      if (datas && datas.status === 200 && datas.data.length > 0) {
        const data = datas.data[0];
        if (data) {
          this.cartStoreInfo = data;
          this.calcuate();
        } else {
          this.util.errorMessage('Chefs not found');
        }
      }
    }, error => {
      console.log(error);
      this.util.errorMessage(this.util.translate('Something went wrong'));
    }).catch(error => {
      console.log(error);
      this.util.errorMessage(this.util.translate('Something went wrong'));
    });
  }

  clearCart() {
    this.cart = [];
    this.itemId = [];
    this.totalPrice = 0;
    this.packagingPrice = 0;
    this.grandTotal = 0;
    this.coupon = undefined;
    this.discount = 0;
    this.serviceTax = 0;
    this.orderPrice = 0;
    this.datetime = undefined;
    this.stores = [];
    this.util.clearKeys('cart');
    this.totalItem = 0;
    this.calcuate();
  }

  addVariations(info, cart, type) {
    if (type === 'new') {
      this.cart.push(info);
      this.itemId.push(info.id);
    } else if (type === 'sameChoice') {
      const index = this.cart.findIndex(x => x.id === info.id);
      this.cart[index].selectedItem = info.selectedItem;
    } else if (type === 'newCustom') {
      const index = this.cart.findIndex(x => x.id === info.id);
      this.cart[index].selectedItem = info.selectedItem;
      this.cart[index].quantiy = info.quantiy;
    }

    this.calcuate();
  }

  addItem(item) {
    this.cart.push(item);
    this.itemId.push(item.id);
    this.calcuate();
  }

  addQuantity(quantity, id) {
    this.cart.forEach(element => {
      if (element.id === id) {
        element.quantiy = quantity;
      }
    });
    this.calcuate();
  }

  removeItem(id) {
    this.cart = this.cart.filter(x => x.id !== id);
    this.itemId = this.itemId.filter(x => x !== id);

    this.calcuate();
  }


  async calcuate() {
    // new
    const item = this.cart.filter(x => x.quantiy > 0);
    this.cart.forEach(element => {
      if (element.quantiy === 0) {
        element.selectedItem = [];
      }
    });
    this.totalPrice = 0;
    this.packagingPrice = 0;
    this.totalItem = 0;
    this.cart = [];
    item.forEach(element => {
      if (element && element.selectedItem && element.selectedItem.length > 0 && element.size === '1') {
        let subPrice = 0;
        element.selectedItem.forEach(subItems => {
          subItems.item.forEach(realsItems => {
            subPrice = subPrice + (realsItems.value);
          });
          subPrice = subPrice * subItems.total;
          this.totalItem = this.totalItem + subItems.total;
        });
        this.totalPrice = this.totalPrice + subPrice;
        if(this.cartStoreInfo.uid == 39208){
          this.packagingPrice = ((this.totalPrice * 7)/100).toFixed(2);
        }
      } else if (element && element.selectedItem && element.selectedItem.length > 0 && element.size === '0') {
        let subPrice = 0;
        element.selectedItem.forEach(subItems => {
          subPrice = 0;
          subItems.item.forEach(realsItems => {
            subPrice = subPrice + (realsItems.value);
          });
          subPrice = subPrice * subItems.total;
          this.totalItem = this.totalItem + subItems.total;
          this.totalPrice = this.totalPrice + subPrice;
          if(this.cartStoreInfo.uid == 39208){
            this.packagingPrice = ((this.totalPrice * 7)/100).toFixed(2);
          }
        });
      } else {
        this.totalItem = this.totalItem + element.quantiy;
        this.totalPrice = this.totalPrice + (parseFloat(element.price) * parseInt(element.quantiy));
        if(this.cartStoreInfo.uid == 39208){
          this.packagingPrice = ((this.totalPrice * 7)/100).toFixed(2);
        }
      }
      this.cart.push(element);
    });
    localStorage.removeItem('userCart');
    localStorage.setItem('userCart', JSON.stringify(this.cart));
    this.util.clearKeys('userCart');
    this.util.setKeys('userCart', JSON.stringify(this.cart));
    this.totalPrice = parseFloat(this.totalPrice).toFixed(2);
    if(this.cartStoreInfo.uid == 39208){
      this.packagingPrice = ((this.totalPrice * 7)/100).toFixed(2);
    }
    let tax = 0;
    if(this.cartStoreInfo && this.cartStoreInfo.gst_status==1){
      const appTax = this.util.general && this.util.general.tax ? parseFloat(this.util.general.tax) : 0;
      var custom_gst = parseInt(localStorage.getItem('gst'));
      if(custom_gst > 0){
        tax = (parseFloat(this.totalPrice) * custom_gst) / 100;
      }else{
        tax = (parseFloat(this.totalPrice) * appTax) / 100;
      }
    }
    this.orderTax = tax.toFixed(2);
    let distance;
    if (this.deliveryAddress && this.deliveryAddress.address && this.cartStoreInfo && this.cartStoreInfo.address) {
      distance = await this.distanceInKmBetweenEarthCoordinates(this.deliveryAddress.lat, this.deliveryAddress.lng,
        this.cartStoreInfo.lat, this.cartStoreInfo.lng);
        if(distance >= 3 && distance <= 6){
          distance = distance + 2;
        }else if(distance >= 7 && distance <= 9){
          distance = distance + 3;
        }else if(distance > 9){
          distance = distance + 4;
        }
    } else {
      distance = 0;
    }
    if (parseFloat(this.freeShipping) > parseFloat(this.totalPrice)) {
      if (this.shipping === 'km') {
        console.log('Distance '+distance);
        console.log('Shipping Price '+this.shippingPrice);
        const distancePricer = distance * this.shippingPrice;
        this.deliveryPrice = Math.floor(distancePricer).toFixed(2);
        this.deliveryPrice = (+parseFloat(this.deliveryPrice) + +((parseFloat(this.deliveryPrice) * 12)/100)).toFixed(2); //delivery charge + 12% gst
        if(parseFloat(this.deliveryPrice) < 30){
          this.deliveryPrice = 30;
        }
      } else {
        this.deliveryPrice = (this.shippingPrice + ((this.shippingPrice * 12)/100)).toFixed(2); //delivery charge + 12% gst
        if(parseFloat(this.deliveryPrice) < 30){
          this.deliveryPrice = 30;
        }
      }
    } else {
      this.deliveryPrice = 0;
    }
    //this.deliveryPrice = this.deliveryPrice.toFixed(2);
    this.grandTotal = parseFloat(this.totalPrice) + parseFloat(this.orderTax) + parseFloat(this.deliveryPrice);
    if(this.cartStoreInfo.uid == 39208){
      this.grandTotal = parseFloat(this.grandTotal) + parseFloat(this.packagingPrice);
    }
    this.grandTotal = this.grandTotal.toFixed(2);
    if (this.coupon && this.coupon.code && this.totalPrice >= parseFloat(this.coupon.min)) {
      if (this.coupon.type === 'per') {
        function percentage(num, per) {
          return (num / 100) * per;
        }
        const totalPrice = percentage(parseFloat(this.totalPrice).toFixed(2), this.coupon.discount);
        this.discount = totalPrice.toFixed(2);
        if(parseFloat(this.discount) > parseFloat(this.coupon.upto)){
          this.discount = parseFloat(this.coupon.upto);
        }
        const appTax = this.util.general && this.util.general.tax ? parseFloat(this.util.general.tax) : 0;
        this.orderTax = ((parseFloat(this.totalPrice) - parseFloat(this.discount)) * appTax) / 100;
        this.grandTotal = parseFloat(this.totalPrice) + parseFloat(this.orderTax) + parseFloat(this.deliveryPrice);
        if(this.cartStoreInfo.uid == 39208){
          this.grandTotal = parseFloat(this.grandTotal) + parseFloat(this.packagingPrice);
        }
        this.grandTotal = this.grandTotal - this.discount;
        this.grandTotal = this.grandTotal.toFixed(2);
      } else {
        this.discount = this.coupon.discount;
        this.grandTotal = parseFloat(this.totalPrice) + parseFloat(this.orderTax) + parseFloat(this.deliveryPrice);
        if(this.cartStoreInfo.uid == 39208){
          this.grandTotal = parseFloat(this.grandTotal) + parseFloat(this.packagingPrice);
        }
        this.grandTotal = this.grandTotal - this.discount;
        this.grandTotal = this.grandTotal.toFixed(2);
      }
    } else {
      this.coupon = null;
      localStorage.removeItem('coupon');
    }
    if (this.totalItem === 0) {
      const lng = localStorage.getItem('language');
      const selectedCity = localStorage.getItem('selectedCity');
      localStorage.setItem('language', lng);
      localStorage.setItem('selectedCity', selectedCity);
      this.totalItem = 0;
      this.totalPrice = 0;
    }
  }

  checkProductInCart(id) {
    return this.itemId.includes(id);
  }

  degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
  }

  distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    const earthRadiusKm = 6371;

    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);

    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
  }
}
