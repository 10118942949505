import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {
  
  allRest: any[] = [];
  allDish: any[] = [];
  
  haveData: boolean;
  activeTab = 'home';
  headerMode: boolean;
  activeFilter: any;
  totalRest: any;
  searchin: any = '';
  search_show = false;
  search_dropdown(show){
    if(show == true){
      this.search_show = show;
    }else{
      setTimeout(() => {
          this.search_show = show;
      }, 500);
    }
    
  }

  languages: any;
  selectedLanguage: any;
  constructor(
    private router: Router,
    public api: ApiService,
    public util: UtilService,
    private chmod: ChangeDetectorRef,
    private cart: CartService) {
    this.headerMode = false;
    this.haveData = false;
    this.selectedLanguage = 'English';
    this.getLangs();
    this.util.subscribeHeader().subscribe(data => {
      if (data) {
        this.headerMode = data.header;
        this.totalRest = data.total;
        if (data && data.active !== undefined) {
          this.activeFilter = data.active;
        }
      }

      this.chmod.detectChanges();
    });
    
  }

  addFilter(item) {
    this.activeFilter = item;
    this.util.publishFilterCode(item);
  }

  goToRestDetail(item) {
    this.util.publishHeader({ header: false, total: this.allRest.length, active: this.activeFilter });
    this.router.navigate(['order', item.uid, item.name.replace(/\s+/g, '-').toLowerCase()])
  }

  goTodishRestDetail(item) {
    this.router.navigate(['order', item.uid, item.sname.replace(/\s+/g, '-').toLowerCase()])
  }


  topsearch(event) {
    if(event!=''){
      this.haveData = true;
    const param = {
      lat: localStorage.getItem('lat'),
      lng: localStorage.getItem('lng'),
      distance: 10,
      type: 1,
      search:event
    };

    this.api.post('stores/nearMe', param).then((data: any) => {
      if (data && data.status === 200 && data.data.rest.length > 0) {
        this.allRest = [];
        data.data = data.data.rest.filter(x => x.status === '1');
        data.data.forEach(async (element) => {
          element.rating = parseFloat(element.rating);
          if (element.cusine && element.cusine !== '') {
            element.cusine = JSON.parse(element.cusine).join();
          }
          this.allRest.push(element);
        });
        this.chmod.detectChanges();
      } else {
        this.allRest = [];
      }
    }, error => {
      console.log(error);
      this.allRest = [];
      this.allDish = [];
    }).catch(error => {
      console.log(error);
      this.allRest = [];
    });



    this.api.post('stores/dishes', param).then((data: any) => {
      if (data && data.status === 200 && data.data.length > 0) {
        this.allDish = [];
        data.data = data.data.filter(x => x.status === '1');
        data.data.forEach(async (element) => {
          this.allDish.push(element);
        });
        this.chmod.detectChanges();
      } else {
        this.allDish = [];
      }
    }, error => {
      console.log(error);
      this.allDish = [];
    }).catch(error => {
      console.log(error);
      this.allDish = [];
    });
    }else{
      this.haveData = false;
    }
  }

  getAddressName() {
    const location = localStorage.getItem('address');
    if (location && location != null && location !== 'null') {
      return location.length > 30 ? location.slice(0, 30) + '....' : location;;
    }
    localStorage.clear();
    return 'Choose location first';
  }

  changeLanguage(value) {
    const item = this.languages.filter(x => x.file === value.file);
    if (item && item.length > 0) {
      localStorage.setItem('language', value.file);
      window.location.reload();
    }
  }

  haveLocation() {
    const location = localStorage.getItem('location');
    if (location && location != null && location !== 'null') {
      return true;
    }
    return false;
  }

  haveSigned() {
    const uid = localStorage.getItem('uid');
    if (uid && uid != null && uid !== 'null') {
      return true;
    }
    return false;
  }

  getLangs() {
    this.api.get('lang').then((data: any) => {
      if (data && data.status === 200 && data.data.length) {
        this.languages = data.data;
        const lng = localStorage.getItem('language');
        if (lng && lng !== null && lng !== '') {
          const selectedLang = this.languages.filter(x => x.file === lng);
          if (selectedLang && selectedLang.length) {
            this.selectedLanguage = selectedLang[0].name;
          }
        } else {
          const defaultLanguages = this.languages.filter(x => x.is_default === '1');
          if (defaultLanguages && defaultLanguages.length) {
            this.selectedLanguage = defaultLanguages[0].name;
          }
        }
      } else {
        this.selectedLanguage = 'English';
      }
    }, error => {
      console.log(';error in languge', error);
      this.selectedLanguage = 'English';
    }).catch((error => {
      console.log('error->>>', error);
      this.selectedLanguage = 'English';
    }))
  }

  ngOnInit(): void {
  }

  goToHome() {
    if(localStorage.getItem('address') === null){
      this.activeTab = 'home';
      localStorage.removeItem('location');
      localStorage.removeItem('address');
      this.router.navigate(['/home']);
      
    }else{
      this.activeTab = 'Chefs';
      this.router.navigate(['/chefs']);
    }
    
  }

  goToSearch() {
    this.activeTab = 'search';
    this.router.navigate(['/search']);
  }

  goToOffers() {
    this.activeTab = 'offers';
    this.router.navigate(['/offers']);
  }

  goToSettings(item) {
    this.activeTab = 'settings';
    const name = (this.util.userInfo.first_name + '-' + this.util.userInfo.last_name).toLowerCase();
    this.router.navigate(['user', name, item]);
  }

  goTocart(item) {
    this.activeTab = 'settings';
    const name = (this.util.userInfo.first_name + '-' + this.util.userInfo.last_name).toLowerCase();
    //this.router.navigate(['user', name, item]);
    this.router.navigate(['cart']);
  }

  goToAccount() {
    this.activeTab = 'account';
    this.router.navigate(['/account']);
  }

  goToCart() {
    this.activeTab = 'cart';
    this.router.navigate(['/cart']);
  }

  getAddress() {
    const location = localStorage.getItem('address');
    if (location && location != null && location !== 'null') {
      return location.length > 30 ? location.slice(0, 30) + '....' : location;;
    }
    return this.util.translate('Choose your Location');
  }

  logout() {
    localStorage.removeItem('uid');
    this.cart.cart = [];
    this.cart.itemId = [];
    this.cart.totalPrice = 0;
    this.cart.grandTotal = 0;
    this.cart.coupon = null;
    this.cart.discount = null;
    this.util.clearKeys('cart');
    this.router.navigate(['']);
  }

  goToHelp() {
    this.activeTab = 'help';
    this.router.navigate(['help']);
  }
  stringArray = Array(9);
  getPageName() {
    this.stringArray = this.router.url.split("/");
    
    //if(this.router.url === "/chefs" || this.router.url == 'fastest-restaurant' || this.stringArray[1] == 'vendor-category'){
    if(this.router.url === "/" || this.router.url === "/home"){
      return false;
    }
    return true;
    this.chmod.detectChanges();
  }
  

  goToFaqs() {
    this.activeTab = 'faq';
    this.router.navigate(['faq']);
  }

  showAddressChangePopup() {
    this.util.publishAddressPopup();
  }
}
