<footer class="main-footer">
	<div class="container">
		<div class="row">
			<div class="col-md-4">
				<div class="logo-foot">
					<a href="javascript:void(0);" class="footer-logo"> 
						<img src="assets/imgs/footer-logo.jpg">
					</a>
					<p *ngIf="util.web_footer">
					  {{util.web_footer}}
					</p>
				</div>
			</div>
			<div class="col-md-2">
				<div class="list-cents">
					<h4> {{util.translate('Learn')}} </h4>
					<ul class="link-foot">
						<!--<li> <a href="javascript:void(0)" (click)="goToAbout()"> {{util.translate('Gift Cards')}} </a> </li>-->
						<!--<li> <a href="javascript:void(0)" (click)="goToNotice()"> {{util.translate('Help Center')}} </a> </li>-->
						<!--<li> <a href="javascript:void(0)" (click)="goToFaqs()"> {{util.translate('COVID-19 Safety')}} </a> </li>-->
					</ul>
				</div>
			</div>
			<div class="col-md-2">
				<div class="list-cents">
					<h4> {{util.translate('Follow')}} </h4>
					<ul class="link-foot">
						<li> <a [href]="util.facebook_link" target="_blank" rel="noopener noreferrer"> {{util.translate('Facebook')}} </a> </li>
						<li> <a [href]="util.instagram_link" target="_blank" rel="noopener noreferrer"> {{util.translate('Instagram')}} </a> </li>
						<li> <a [href]="util.twitter_link" target="_blank" rel="noopener noreferrer"> {{util.translate('Twitter')}} </a> </li>
					</ul>
				
				</div>
			</div>
			<div class="col-md-2">
				<div class="list-cents">
					<h4> {{util.translate('Join')}} </h4>
					<ul class="link-foot">
						<li> <a (click)="goToHelp();" target="_blank"> {{util.translate('Become a Chef')}} </a> </li>
						<li> <a href="javascript:void(0)" (click)="goToCareer()"> {{util.translate('Careers')}} </a> </li>
						<li> <a href="javascript:void(0)" (click)="goToSafety()"> {{util.translate('Covid Safety')}} </a> </li>
					</ul>
				</div>
			</div>
			<div class="col-md-2">
				<div class="list-cents">
					<h4> {{util.translate('Secure Payment')}} </h4>
					<img src="assets/imgs/cards.png" class="img-cards">
				</div>
			</div>
		</div>
	</div>
</footer>
<div class="copyright">
	<div class="container">
		<div class="span-boxs">
			<p> © {{getCopyright()}} {{util.translate('FoodCloud')}}:<!--<a [href]="util.websiteURL">
				{{util.websiteName}}</a>--> All rights reserved.</p>
			<a href="javascript:void(0)" (click)="goToPrivacy()"> {{util.translate('Privacy Policy')}} </a>
			<a href="javascript:void(0)" (click)="goToCookies()"> {{util.translate('Terms of Service')}} </a>
		</div>
	</div>
</div>
<!-- Footer -->
