<header class="main_header">
	<div class="top-headr">
		<p> {{util.translate('Get Rs. 100 Off On Your First Order. Use code: FC100')}} </p> 
	</div>
  <div class="main-head">
	  <div class="container">
		<div class="header_items" *ngIf="headerMode === false">
		  <div class="brand_items">
			<a class="navbar-brand" (click)="goToHome()">
			  <img src="assets/imgs/logo.jpg" class="logo_img">
			</a>
		  </div>
		  <!--<div class="center-seach" *ngIf="getPageName() !== false">-->
			<div class="center-seach" *ngIf="getPageName() !== false">
			<div class="left-str position-relative"> 
				<span> <mdb-icon fas icon="search"></mdb-icon> </span>
				<input type="text" class="top-sr" (ngModelChange)="topsearch($event)" (focus)="search_dropdown(true)" (focusout)="search_dropdown(false)" [(ngModel)]="searchin" [placeholder]="util.translate('Search chefs, dishes')">

				<div class="search-sub-container" *ngIf="haveData ===true">
					<div class="results-container-wrapper" [hidden]="search_show === false">
						<div class="results-container" *ngIf="(allRest?.length>0 || allDish?.length>0)">
							<h1 class="result-section-header" color="#373737" *ngIf="allRest?.length">Chefs</h1>
							
							<a class="styled-result-link" *ngFor="let item of allRest">
								<div class="result-item" data-cy="search-result-mobile" (click)="goToRestDetail(item)">
									<div class="result-image-container shef-result"><img class="sc-fmlJLJ gBVTDm result-image shef-result-image" height="72" width="72" [src]="api.mediaURL+item.cover"></div>
									<div class="result-text-container">
										<div class="result-title">{{ (item.name.length>30)? (item.name | slice:0:30)+'..':(item.name) }}</div>
										<div class="result-subtitle">{{ (item.cusine.length>20)? (item.cusine | slice:0:20)+'..':(item.cusine) }}</div>
									</div>
									<div class="result-rating-container" style="margin-left: auto;">
										<div style="display: flex;">
											<p>{{item.rating}}</p>
											<!--<img src="https://cdn.shef.com/static/media/star-red-fill.a78bd140.svg" height="15" alt="Star">-->
											<img src="https://foodcloud.in/assets/imgs/star.png" height="15" alt="Star">

										</div>
									</div>
								</div>
							</a>
							
							<h1 class="result-section-header" *ngIf="allDish?.length">Dishes</h1>
							
							<a class="styled-result-link" *ngFor="let item of allDish">
								<div class="result-item" data-cy="search-result-mobile" (click)="goTodishRestDetail(item)">
									<div class="result-image-container"><img class="result-image" [hidden]="item.cover === ''" [src]="api.mediaURL+item.cover" width="108" height="72" alt="{{item.name}} image"></div>
									<div class="result-text-container">
										<div class="result-title" data-cy="search-result-name-mobile">{{item.name}}</div>
										<div class="result-subtitle" data-cy="search-result-shef-mobile">{{item.sname}}</div>
									</div>
								</div>
							</a>
						</div>
						<div class="result-not-found-container" *ngIf="(allRest?.length==0 && allDish?.length==0)">
							<h1 class="result-section-header">Searching..<div class="result-not-found-message">If not found, Try another dish or chef</div></h1>
						</div>
					</div>
				</div>
			</div>
			<div class="imglos mb-gap">
				<a class="subHeaders_address" (click)="showAddressChangePopup()"><img src="assets/imgs/placeholder2.png">
				{{getAddressName()}}</a>
			</div>
		  </div>
		  <div class="otherLinks">
			<!--<a (click)="goToHelp();" target="_blank" class="nav-link waves-light" mdbWavesEffect *ngIf="haveSigned() === false">
			  <label [class.active]="activeTab == 'become'">{{util.translate('Become a Chef')}} </label>
			</a>-->
			<a (click)="goToHelp();" target="_blank" class="nav-link waves-light" mdbWavesEffect>
				<label [class.active]="activeTab == 'become'">{{util.translate('Become a Chef')}} </label>
			  </a>
			<a class="nav-link waves-light login-outline" mdbWavesEffect (click)="util.publishModalPopup('login')"
			  *ngIf="haveSigned() ===false">
			  <label [class.active]="activeTab == 'account'">{{util.translate('Login')}} </label>
			</a>
			<div class="nav-item avatar dropdown" dropdown *ngIf="haveSigned()">
			  <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect> Hi {{util.userInfo.first_name}}</a>
			  <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
				<a class="dropdown-item waves-light" mdbWavesEffect
				  (click)="goToSettings('profile')">{{util.translate('Profile')}}</a>
				<a class="dropdown-item waves-light" mdbWavesEffect
				  (click)="goToSettings('address')">{{util.translate('Address')}}</a>
				<a class="dropdown-item waves-light" mdbWavesEffect
				  (click)="goToSettings('order')">{{util.translate('Orders')}}</a>
			  	<a class="dropdown-item waves-light" mdbWavesEffect
				  (click)="goTocart('cart')">{{util.translate('Cart')}}</a>
				<!-- <a class="dropdown-item waves-light" mdbWavesEffect (click)="goToHelp()">{{util.translate('Help')}}</a>
				<a class="dropdown-item waves-light" mdbWavesEffect (click)="goToFaqs()">{{util.translate('FAQs')}}</a> -->
				<div class="divider dropdown-divider"></div>
				<a class="dropdown-item waves-light" mdbWavesEffect (click)="logout()">{{util.translate('Log out')}}</a>
			  </div>
			</div>
		  </div>
		</div>
		<div class="filterItems" *ngIf="headerMode === true">
		  <div class="allItems">
			<label class="totalRest"> {{totalRest}} {{util.translate('chefs in')}} {{getAddress()}}
			</label>
			<div class="filter_btns">
			  <a class="nav-link waves-light" mdbWavesEffect>
				<label [class.active]="activeFilter == 0" (click)="addFilter(0)">{{util.translate('Highest Rating')}}</label>
			  </a>

			  <a class="nav-link waves-light" mdbWavesEffect>
				<label [class.active]="activeFilter == 1" (click)="addFilter(1)">{{util.translate('Fastest
				  Delivery')}}</label>
			  </a>

			  <a class="nav-link waves-light" mdbWavesEffect>
				<label [class.active]="activeFilter == 2" (click)="addFilter(2)">{{util.translate('Cost')}}</label>
			  </a>

			  <a class="nav-link waves-light" mdbWavesEffect>
				<label [class.active]="activeFilter == 3" (click)="addFilter(3)">{{util.translate('A-Z')}}</label>
			  </a>

			  <a class="nav-link waves-light" mdbWavesEffect>
				<label [class.active]="activeFilter == 4" (click)="addFilter(4)">{{util.translate('Z-A')}}</label>
			  </a>
			</div>
		  </div>
		</div>
	  </div>
  </div>
</header>